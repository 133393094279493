import React from "react";
import PropTypes from "prop-types";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import VideoSectionWrapper from "./videoSection.style";
import { width } from "styled-system";

const VideoSection = ({
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  imageWrapper,
}) => {
  return (
    <VideoSectionWrapper id="videoSection">
      <Container>
        <Box {...sectionHeader}>
          <Text content="HOW WE WORK" {...sectionSubTitle} />
          <Heading content="Take a look how we enjoy work" {...sectionTitle} />
        </Box>
        <Box className="figure" {...imageWrapper} style={{ width: "100%" }}>
          <iframe
            width="100%"
            height="625"
            src="https://www.youtube.com/embed/s8M1enbtG7I?si=bkLZ8OoQYRphQpSk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowfullscreen="true"
          ></iframe>
        </Box>
      </Container>
    </VideoSectionWrapper>
  );
};

VideoSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  imageWrapper: PropTypes.object,
};

VideoSection.defaultProps = {
  sectionHeader: {
    mb: ["40px", "56px"],
  },
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#ffc21b",
    mb: "10px",
  },
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
  },
  imageWrapper: {
    display: "block",
  },
};

export default VideoSection;

// import React from "react";
// // import { useStaticQuery, graphql } from 'gatsby';
// import PropTypes from "prop-types";
// // import { openModal, closeModal } from '@redq/reuse-modal';
// import Box from "common/components/Box";
// import Text from "common/components/Text";
// import Heading from "common/components/Heading";
// // import Button from 'common/components/Button';
// import Container from "common/components/UI/Container";
// // import GatsbyImage from 'common/components/GatsbyImage';
// import VideoSectionWrapper from "./videoSection.style";

// // const IntroVideo = () => (
// //   <iframe

// //     width="560"
// //     height="315"
// //     src="https://www.youtube.com/watch?v=yBptWyMEsuY"
// //     frameBorder="0"
// //     allow="accelerometer; autoplay;"
// //     allowFullScreen
// //   />
// // );

// // const CloseModalButton = () => (
// //   <Button
// //     className="modalCloseBtn"
// //     variant="fab"
// //     onClick={() => closeModal()}
// //     icon={<i className="flaticon-plus-symbol" />}
// //   />
// // );

// const VideoSection = ({
//   sectionHeader,
//   sectionTitle,
//   buttonStyle,
//   sectionSubTitle,
//   imageWrapper,
// }) => {
//   // Video modal handler
//   // const handleVideoModal = () => {
//   //   openModal({
//   //     config: {
//   //       className: 'video-modal',
//   //       disableDragging: true,
//   //       default: {
//   //         width: '100%',
//   //         height: '100%',
//   //         x: 0,
//   //         y: 0,
//   //       },
//   //     },
//   //     component: IntroVideo,
//   //     componentProps: {},
//   //     closeComponent: CloseModalButton,
//   //     closeOnClickOutside: false,
//   //   });
//   // };

//   // const Data = useStaticQuery(graphql`
//   //   query {
//   //     featureImage: file(
//   //       relativePath: { eq: "image/agency/preview-image.jpg" }
//   //     ) {
//   //       childImageSharp {
//   //         gatsbyImageData(
//   //           layout: FULL_WIDTH
//   //           placeholder: BLURRED
//   //           formats: [AUTO, WEBP, AVIF]
//   //         )
//   //       }
//   //     }
//   //   }
//   // `);

//   return (
//     <VideoSectionWrapper id="videoSection">
//       <Container>
//         <Box {...sectionHeader}>
//           <Text content="HOW WE WORK" {...sectionSubTitle} />
//           <Heading content="Take a look how we enjoy work" {...sectionTitle} />
//         </Box>
//         <Box className="figure" {...imageWrapper}>
//           {/* <GatsbyImage
//             src={
//               (Data.featureImage !== null) | undefined
//                 ? Data.featureImage.childImageSharp.gatsbyImageData
//                 : {}
//             }
//             alt="Video Preview Image"
//           /> */}

//           <iframe
//             title="comrex"
//             width="100%"
//             height="315"
//             src="https://www.youtube.com/embed/qSS6ZiOlgy4?rel=0&amp;autoplay=1"
//             frameBorder="0"
//             allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//           />

//           {/* <Box className="fig__caption">
//             <Button
//               {...buttonStyle}
//               icon={<i className="flaticon-youtube" />}
//               onClick={handleVideoModal}
//               aria-label="play"
//             />
//           </Box> */}
//         </Box>
//       </Container>
//     </VideoSectionWrapper>
//   );
// };

// // VideoSection style props
// VideoSection.propTypes = {
//   sectionHeader: PropTypes.object,
//   row: PropTypes.object,
//   col: PropTypes.object,
//   sectionTitle: PropTypes.object,
//   sectionSubTitle: PropTypes.object,
//   memberName: PropTypes.object,
//   designation: PropTypes.object,
//   imageWrapper: PropTypes.object,
// };

// // VideoSection default style
// VideoSection.defaultProps = {
//   // section header default style
//   sectionHeader: {
//     mb: ["40px", "56px"],
//   },
//   // sub section default style
//   sectionSubTitle: {
//     as: "span",
//     display: "block",
//     textAlign: "center",
//     fontSize: "14px",
//     letterSpacing: "0.15em",
//     fontWeight: "700",
//     color: "#10ac84",
//     mb: "10px",
//   },
//   // section title default style
//   sectionTitle: {
//     textAlign: "center",
//     fontSize: ["20px", "24px"],
//     fontWeight: "400",
//     color: "#0f2137",
//     letterSpacing: "-0.025em",
//     mb: "0",
//   },
//   // button default design
//   buttonStyle: {
//     variant: "textButton",
//     p: 0,
//     color: "#ec4444",
//     fontSize: "71px",
//   },
//   imageWrapper: {
//     display: " block",
//   },
// };

// export default VideoSection;
